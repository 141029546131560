import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import InstarVisionCard from 'components/Software/Windows/InstarVision/Image_Adjust/instarVisionCard01';
import NavButtons from 'components/Software/Windows/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision v3 for Windows",
  "path": "/Software/Windows/InstarVision/Image_Adjust/",
  "dateChanged": "2018-01-25",
  "author": "Mike Polinowski",
  "excerpt": "Set up your video canvas area with one click in the Layout menu. Choose a layout that fits your needs and amount of active cameras. The layout will always reset to its default when you restart the software - the start-up default can be set in the System menu.",
  "image": "../P_SearchThumb_InstarVision_Windows.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Windows.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='InstarVision v3 for Windows' dateChanged='2018-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.' image='/images/Search/P_SearchThumb_InstarVision_Windows.png' twitter='/images/Search/P_SearchThumb_InstarVision_Windows.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/InstarVision/Image_Adjust/' locationFR='/fr/Software/Windows/InstarVision/Image_Adjust/' crumbLabel="Image Adjust" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/369e70e49bbd9b9c3106df829c88d54c/29114/Banner_en-InstarVision2-0_Image-Adjust.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAg0lEQVQI143HsQrCMBhF4f9V9Pkc3BS7OzRxiggKgqCv4uJaIRXXIrg4NKFpGsoR7C698HE5Qh8heGg9dA00NQQHJMbOGMNylUEKiLp7dOEGtkU/ErpMqLJD2fhXbiObZ0+21izmM/LCIXJ8I/sKObyQ0we5OORcDz+KZ7q9Mtndfv0F0RPAwznue1cAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/369e70e49bbd9b9c3106df829c88d54c/e4706/Banner_en-InstarVision2-0_Image-Adjust.avif 230w", "/en/static/369e70e49bbd9b9c3106df829c88d54c/d1af7/Banner_en-InstarVision2-0_Image-Adjust.avif 460w", "/en/static/369e70e49bbd9b9c3106df829c88d54c/7f308/Banner_en-InstarVision2-0_Image-Adjust.avif 920w", "/en/static/369e70e49bbd9b9c3106df829c88d54c/e1c99/Banner_en-InstarVision2-0_Image-Adjust.avif 1380w", "/en/static/369e70e49bbd9b9c3106df829c88d54c/76ea5/Banner_en-InstarVision2-0_Image-Adjust.avif 1840w", "/en/static/369e70e49bbd9b9c3106df829c88d54c/f696c/Banner_en-InstarVision2-0_Image-Adjust.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/369e70e49bbd9b9c3106df829c88d54c/a0b58/Banner_en-InstarVision2-0_Image-Adjust.webp 230w", "/en/static/369e70e49bbd9b9c3106df829c88d54c/bc10c/Banner_en-InstarVision2-0_Image-Adjust.webp 460w", "/en/static/369e70e49bbd9b9c3106df829c88d54c/966d8/Banner_en-InstarVision2-0_Image-Adjust.webp 920w", "/en/static/369e70e49bbd9b9c3106df829c88d54c/445df/Banner_en-InstarVision2-0_Image-Adjust.webp 1380w", "/en/static/369e70e49bbd9b9c3106df829c88d54c/78de1/Banner_en-InstarVision2-0_Image-Adjust.webp 1840w", "/en/static/369e70e49bbd9b9c3106df829c88d54c/882b9/Banner_en-InstarVision2-0_Image-Adjust.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/369e70e49bbd9b9c3106df829c88d54c/81c8e/Banner_en-InstarVision2-0_Image-Adjust.png 230w", "/en/static/369e70e49bbd9b9c3106df829c88d54c/08a84/Banner_en-InstarVision2-0_Image-Adjust.png 460w", "/en/static/369e70e49bbd9b9c3106df829c88d54c/c0255/Banner_en-InstarVision2-0_Image-Adjust.png 920w", "/en/static/369e70e49bbd9b9c3106df829c88d54c/b1001/Banner_en-InstarVision2-0_Image-Adjust.png 1380w", "/en/static/369e70e49bbd9b9c3106df829c88d54c/161ec/Banner_en-InstarVision2-0_Image-Adjust.png 1840w", "/en/static/369e70e49bbd9b9c3106df829c88d54c/29114/Banner_en-InstarVision2-0_Image-Adjust.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/369e70e49bbd9b9c3106df829c88d54c/c0255/Banner_en-InstarVision2-0_Image-Adjust.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "side-navigation---image-adjust",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#side-navigation---image-adjust",
        "aria-label": "side navigation   image adjust permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Side Navigation - Image Adjust`}</h2>
    <InstarVisionCard mdxType="InstarVisionCard" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`Please note`}</strong>{` that video and audio quality settings are only available for cameras added to the [software via an INSTAR camera profile were. For example, if you add an INSTAR camera via the `}<strong parentName="p">{`ONVIF, RTSP, or P2P profile`}</strong>{` (that is, select `}<strong parentName="p">{`IP Camera Model`}</strong>{` when adding it), these features will not be available.`}</p>
    <h3 {...{
      "id": "options",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#options",
        "aria-label": "options permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Options`}</h3>
    <p>{`The available setting options depend on the selected camera model:`}</p>
    <h4 {...{
      "id": "picture-settings-for-an-instar-1080p-fullhd-ip-camera--link-toweb_user_interface1080p_seriesmultimediavideowebuilink",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#picture-settings-for-an-instar-1080p-fullhd-ip-camera--link-toweb_user_interface1080p_seriesmultimediavideowebuilink",
        "aria-label": "picture settings for an instar 1080p fullhd ip camera  link toweb_user_interface1080p_seriesmultimediavideowebuilink permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Picture settings for an INSTAR 1080p FullHD IP Camera  (`}<Link to="/Web_User_Interface/1080p_Series/Multimedia/Video/" mdxType="Link">{`WebUI`}</Link>{`)`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5faf618250419c999b3a3c9ca0c9cccc/c929c/de_imageadjust_fullhd.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAADUklEQVQozx2M71OSBwCA379lH/Zha97yysppU6dTFIUXeREQRAlfEMRfFagoWgMJ/FGaZuJCsVKLeeeldvZhy9Rd7XZtza6tNtv2rTXESnd+8EPPrp67555vj6DVV1NZ5cRQKWMyOyjXyxQoTShKRFRlIjpJR1GxiqLiMir0BsxmI1XmdzWTX6jGZXfw+9OnfJNI8Nfz5wiXJ+8ycW2dkdElhoZvEY3/QN+VnxiaeMDo5Hes3H/C6LV7eHpmmb39kLVHf7L+8BlrP/7B8vomc4v3mYjFCIVC3LxxA0Gp0qHV1iJKLhSaavJKTbQPLTE8tUYwfIXuYICz4T4CvRcIRC4Q9Idodp6iyxekJzRI4Nwg0WiU+fl5EokEQltkALu/B0fXOezdfZi/GsAamUZrbcbf6qS/zYBHFvE4DHhdMj63G7fVQZPNSpOplPNeGztv3rCzu8ve3h5CYCRMcHyQzpFeXJEAzkgEV6sXRUkhPq+VWKSasaCVWLiOaG8boW4//YFOJnubuBq0MdPfwt5/uyRf/sP21haC0abCKGvRyyI19QaaGm10NFTirCqm1liEs7oIoy6fuholHpdEi9NIo6yl0VqMLGXjrdfxYvs1f79M8W/qFcLCyiPurD/m+583uffgMePxWabmlllc3WBpdYPOS6t0DX+L//wCreEEOncUtXsKybuIsvkW9jO3+eXFK65uvuZJcgfh0uRNAv3DXIxNM7uwgqvFw+mOM4xfn8PfE6bcHkFTdxHRdRmlbYBjYjuZmnbyzCHE1mVOj/3GVjLJdirF7s4OwtFjh9BWlKMsVVBhlKh1yEj6csolDdnHM0g7kkt6dimfKarI0djJUtdypKSaw4U60vO0aO3dvH3Le/b39xEkjRpRrSS/IIcyVSHFygK+LMzFbDGiUpdwMCONTw5+yIG0D/jowMd8eugwmcezycjKIT3zc/RVJ0ilUu9NJpMIuVlHOem20+Cux2IyoCkpQFIrURTlo1aXUePyUNPYRWV9F3pLDaI6D0n8AotZi16vQXafIhabIB6fZGZ6GsFdb+Fko41mZyWyVU+T3cIJkxrZbqGhoZa+r+8Qur6B/+ozfPFf8Y3dxdk5RFvnWTw+Px3BfmIT74ZxZmZm+B8TllkGrsjavQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5faf618250419c999b3a3c9ca0c9cccc/e4706/de_imageadjust_fullhd.avif 230w", "/en/static/5faf618250419c999b3a3c9ca0c9cccc/d1af7/de_imageadjust_fullhd.avif 460w", "/en/static/5faf618250419c999b3a3c9ca0c9cccc/7f308/de_imageadjust_fullhd.avif 920w", "/en/static/5faf618250419c999b3a3c9ca0c9cccc/203bb/de_imageadjust_fullhd.avif 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5faf618250419c999b3a3c9ca0c9cccc/a0b58/de_imageadjust_fullhd.webp 230w", "/en/static/5faf618250419c999b3a3c9ca0c9cccc/bc10c/de_imageadjust_fullhd.webp 460w", "/en/static/5faf618250419c999b3a3c9ca0c9cccc/966d8/de_imageadjust_fullhd.webp 920w", "/en/static/5faf618250419c999b3a3c9ca0c9cccc/491b1/de_imageadjust_fullhd.webp 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5faf618250419c999b3a3c9ca0c9cccc/81c8e/de_imageadjust_fullhd.png 230w", "/en/static/5faf618250419c999b3a3c9ca0c9cccc/08a84/de_imageadjust_fullhd.png 460w", "/en/static/5faf618250419c999b3a3c9ca0c9cccc/c0255/de_imageadjust_fullhd.png 920w", "/en/static/5faf618250419c999b3a3c9ca0c9cccc/c929c/de_imageadjust_fullhd.png 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5faf618250419c999b3a3c9ca0c9cccc/c0255/de_imageadjust_fullhd.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "picture-settings-for-an-instar-720p-fullhd-ip-camera-link-toweb_user_interface720p_seriesmultimediavideowebuilink",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#picture-settings-for-an-instar-720p-fullhd-ip-camera-link-toweb_user_interface720p_seriesmultimediavideowebuilink",
        "aria-label": "picture settings for an instar 720p fullhd ip camera link toweb_user_interface720p_seriesmultimediavideowebuilink permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Picture settings for an INSTAR 720p FullHD IP Camera (`}<Link to="/Web_User_Interface/720p_Series/Multimedia/Video/" mdxType="Link">{`WebUI`}</Link>{`)`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5a95a7b3f9d14f074d7bcfba4cd3ccab/c929c/de_imageadjust_hd.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAADUUlEQVQozyXM7VPTBQDA8f0tvehFGZeckiEYIDjXBvttbOyR/WCwB/YAQ2gwxwYJYzAGhwiSAoPFBMTgRSZFLwIPzrSOjrrLg8rqeosiFcEQ5NtZL75vvi8+Er3BgkV0YjZXI1oc6Aw2ZMVGihVK1EolGq2OQplAkUxAozNiNJswmU0YzSL5F1TY7G5+2tjgkzt3+O3JEyTDE8skUqsMDt9jYPBTbiYfER/7jqvjDxke/4rlr39kOHUff+cMMwtrrH7/K6trm6x++zOLq78we/chY6OjdEWjzN6+jUSu1KHR2FBp3cjUFRQUm7k8cI/Bj1eIdI/RFungSnecjp5+OmL9RMJR6l2NtAY7iXS+eteZnEwxNzf3PxiI9eEId+Js7cLRFqe8vQ9rbAqNtZ5ws4vegAG/XYXfacDvthP0evFanfiqrfhMxfQHajh8ccDff+6w988ukvahGJGRq4SG4rhjEVyxGO7mJmRyKcEmK4mYyI1IJYnuGkZ6AkTbwvR2hEj2+EhFqknFL/F05y/+2HrO1vYOErOtBLO9FKNdwOrRU++rIlRnxGOR4TBKcVZcxFBWhLNSQaOrjPoaE167Fq9VTpUmlxafmc3n+0z9vsvjp7tIvri/xtKDdb5Z3+TBo3UmJqeYmV/gy5X1/7o8tEJgYInmvgUauuZReUaRe1IoP1hAWvcZYtsiB+k0L4+OSO/vI7k+MUt7fJBriWmm7y7jutREY8uH3Lw1TyjSjcYRo9R1DZX7IxTVfbyrCnBWHeB8eRR10+c0jWyQTqc5PDxkf28PyZnsU2h0pSiKZeiMWmxOO2X6Ukq1anLPZZHxTj6ZuSWclVnIUzvIEWxkySs4LS0js0CDxt7G0dFLjo+PeXFwgESrFlAJCgqL8ihRSnlfUcQFaT7lohGlIOdkVgZvnXydExmv8caJN3n71Gmyz+WSlZNHZvZ76C1VPHu2zfb2NltbW0jyc87Q4HVQ6/Ugmg2o5UVoBQWyi4UIQgmVbj+Vda2YPK3oxUpUQgFa1XnEcg16vRq7t5FEYpxkcoLpqSkkXo9IQ1019S4Tdqsen0Okyixgd4jU1tqIjy4SvfUD4clNgsnHBG8s4QoNEAhdwR8M0xLpJTH+CkwyPT3Nv8Z7Wh+CVSr0AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5a95a7b3f9d14f074d7bcfba4cd3ccab/e4706/de_imageadjust_hd.avif 230w", "/en/static/5a95a7b3f9d14f074d7bcfba4cd3ccab/d1af7/de_imageadjust_hd.avif 460w", "/en/static/5a95a7b3f9d14f074d7bcfba4cd3ccab/7f308/de_imageadjust_hd.avif 920w", "/en/static/5a95a7b3f9d14f074d7bcfba4cd3ccab/203bb/de_imageadjust_hd.avif 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5a95a7b3f9d14f074d7bcfba4cd3ccab/a0b58/de_imageadjust_hd.webp 230w", "/en/static/5a95a7b3f9d14f074d7bcfba4cd3ccab/bc10c/de_imageadjust_hd.webp 460w", "/en/static/5a95a7b3f9d14f074d7bcfba4cd3ccab/966d8/de_imageadjust_hd.webp 920w", "/en/static/5a95a7b3f9d14f074d7bcfba4cd3ccab/491b1/de_imageadjust_hd.webp 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5a95a7b3f9d14f074d7bcfba4cd3ccab/81c8e/de_imageadjust_hd.png 230w", "/en/static/5a95a7b3f9d14f074d7bcfba4cd3ccab/08a84/de_imageadjust_hd.png 460w", "/en/static/5a95a7b3f9d14f074d7bcfba4cd3ccab/c0255/de_imageadjust_hd.png 920w", "/en/static/5a95a7b3f9d14f074d7bcfba4cd3ccab/c929c/de_imageadjust_hd.png 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5a95a7b3f9d14f074d7bcfba4cd3ccab/c0255/de_imageadjust_hd.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "picture-settings-for-an-instar-vga-ip-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#picture-settings-for-an-instar-vga-ip-camera",
        "aria-label": "picture settings for an instar vga ip camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Picture settings for an INSTAR VGA IP Camera`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b697a3f19ae89620ab6f2cde202169b8/c929c/de_imageadjust_vga.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAADUklEQVQozx3M3VeaBQCAcf6WLrpoq9N2dO7Dr9ycMlD5eFGEVxRQROQ96mICOtBCEPGjxdScOpT5gc7Ni5bW2oV6xrFWx7IudrRaO117tNNxc0jOp2MXz81z8ZPp9DUYTQ4qjTbEKjtavY1Cpcg1hZqyMi0qwUChXPV/QoURg2jEKBqpFKspKNJQX+/kt60tHiws8PLFC2QjkytMTCcZ+mKJ6OCXjMaf0Xf3J6IT3zE8ucqT9S1GZtZoDc0xt7xB8pc/SW5sk/zxd75N/sHCo++5Oz5OOBzm/vw8MmVZOYJQh1rXiFxTQ0GJSFt0icF7Twn0xGgLdBPo6aOr9xZdkVsE/d20OFx0tIcIhk7eMPempll48JD5E9AbGaDeH8LeEaa+s4+qwACWyCw6Sws+TyNhj0irTcUNeyWtjTbaJQnJYqe51kKzsYTPvA28TadI/7PH4etXyAJDPQTHPsc31EdjJIgjEsHhcSNXFOF1W7nTY2E0aCbW08BYr5fuTj/9XT7ivc1MddUyP+Bia/c1sy/3eb6zj0ysK0W0aTHYVFicelqarfiaDDhNcuoMxVirFVSWF2I3K3E5ymlpMCLZdEgWBVYhB2+TyN9vDvlrP83uqwNk36xtsLK+yQ+b26w/22Ryapa5xWUeP93kSfJn/MNreKKreAaW+Di8iNo5jsI5TdmNZYqavsLU+Zh0KgXHR6RTb5ANTd4n0D/I7ViCxKNVHNfduG5+wujMIh2hMIaGbjQNt9E0jqCsHeCC2ssljZfLVd1o3F/jHtsilUqRTv/LwcEBsqwLGQgVWpQlcioMOursNsr1WrQ6Dbm5mWScz+NMTimX5CbyNfVkq+o4p6ghs6icswUCgq2To6O3HB8fkz48RCZoVKhVSq4U5lFaVoRCWcjVoo+oqjagVl0jK+sU7595l9MfvMN7p0/xYUYmF3NzOJedz9mLeehNVnZ399jb22NnZwdZfvZ5rjvtSJKESTSgVlxFUJVQXHwFtaoUm9OFuakTo7MDfbUZtaoAnfoy1VUCer0Gm+QiFpsgHp8kMTuLzCUZaWsx43HqkawCbrsRh6kEyS7S2mwmGlsmNPMr/qlt2uPPab+zgsMXxev7lNZ2PzeD/cQmTsA4iUSC/wDidFp51eDTngAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b697a3f19ae89620ab6f2cde202169b8/e4706/de_imageadjust_vga.avif 230w", "/en/static/b697a3f19ae89620ab6f2cde202169b8/d1af7/de_imageadjust_vga.avif 460w", "/en/static/b697a3f19ae89620ab6f2cde202169b8/7f308/de_imageadjust_vga.avif 920w", "/en/static/b697a3f19ae89620ab6f2cde202169b8/203bb/de_imageadjust_vga.avif 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b697a3f19ae89620ab6f2cde202169b8/a0b58/de_imageadjust_vga.webp 230w", "/en/static/b697a3f19ae89620ab6f2cde202169b8/bc10c/de_imageadjust_vga.webp 460w", "/en/static/b697a3f19ae89620ab6f2cde202169b8/966d8/de_imageadjust_vga.webp 920w", "/en/static/b697a3f19ae89620ab6f2cde202169b8/491b1/de_imageadjust_vga.webp 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b697a3f19ae89620ab6f2cde202169b8/81c8e/de_imageadjust_vga.png 230w", "/en/static/b697a3f19ae89620ab6f2cde202169b8/08a84/de_imageadjust_vga.png 460w", "/en/static/b697a3f19ae89620ab6f2cde202169b8/c0255/de_imageadjust_vga.png 920w", "/en/static/b697a3f19ae89620ab6f2cde202169b8/c929c/de_imageadjust_vga.png 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b697a3f19ae89620ab6f2cde202169b8/c0255/de_imageadjust_vga.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      